<template>
  <div id="detail">
    <van-list loading-text="加载中..." v-model="loading" offset="50" :finished="finished" finished-text="没有更多了"
              @load="onLoad">
    <div v-for="(item) in orderLogs.rows" v-bind:key="item.id">
      <div v-if="!item.is_comment" class="detail-user">
        <p>{{formatDate(item.created_at)}}</p>
        <div class="detail-user-content">
          <p  v-if="item.content">{{item.content}}
            <span v-if="item.ack===false&&item.has_error===false">发送中</span>
            <span class="error" v-else-if="item.ack===false&&item.has_error===true" v-on:click="resend(item.request_id)">重发</span>
          </p>
        </div>
        <div class="detail-user-img" v-for="(index) in item.thumbs" v-bind:key="index.id">
          <img :src="(index)" alt="" >
        </div>
      </div>
      <div v-else class="detail-customer">
        <p>{{formatDate(item.created_at)}}</p>
        <div class="detail-customer-content">
          <p>{{item.content}}</p>
        </div>
      </div>
    </div>
    </van-list>


    <div class="detail-write" v-show="Success">
      <div class="write">
        <textarea v-model="text" v-on:input="write_num()" class="write-input" cols="20" rows="2"></textarea>
      </div>
      <div class="pic" v-on:click="addImg()">
        <img src="/images/pic2.png" alt="">
      </div>
      <div class="submit" :style="(color)" v-on:click="submit()">
        <span>提交</span>
      </div>
    </div>
    <input type="file" ref="filElem"  @change="uploadImg($event)" class="input_file">
    <Popout v-show="isPrpout" v-on:confirm="onConfirm" content="最多上传3张图片!"/>
    <Popout v-show="isFalse" v-on:confirm="onConfirm" :content="(falseContent)"/>
    <Popout v-show="isNum" v-on:confirm="onConfirm"   content="输入内容不能为空"/>
    <Popout v-show="isSuccess" v-on:confirm="onConfirm" content="提交成功!"/>
  </div>
</template>

<script>
import "@/styles/order_details.scss";
import Popout from './Alert.vue'
export default {
  name: "Details",
  created() {
    this.page = 0;
    this.onLoad();
    this.create_ws();
    if (Number(this.$route.query.status)===4){
      this.Success=false;
    }
  },
  components:{
    Popout
  },
  data(){
    return {
      isUpload:false,
      imgUrl:[],
      orderLogs: {rows: [], page: 0},
      picLogs: {rows: []},
      loading: false,
      finished: false,
      page: 0,//页数
      order_total: '',
      isPrpout:false,
      text:'',
      isFalse:false,
      isSuccess:false,
      imgVlaue:[],
      falseContent:'',
      isNum:false,
      ws_url:'wss://api.initap.com/appeal/chat',
      lock:{reconnect:false},
      heart_beat_timer:null,
      socket:null,
      color:'',
      Success:true
    }
  },
  methods:{
    resend(request_id){
      this.orderLogs.rows.forEach((item,index)=>{
        if (item.request_id===request_id){
          console.log(item)
          this.socket.send(JSON.stringify({type:'data',request_id:request_id,data:JSON.stringify({content:item.content,pictures:null,thumbs:null,id:this.$route.query.id})}));
          item.has_error=false;
          this.orderLogs.rows.splice(index,1,item)
        }
      })
    },
    guid() {
      function S4() {
        return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
      }

      return (S4() + S4() + "-" + S4() + "-" + S4() + "-" + S4() + "-" + S4() + S4() + S4());
    },
    create_ws() {
      this.socket = new WebSocket(this.ws_url);
      this.register_ws_handler()
    },
    register_ws_handler() {
      this.socket.onopen = () => {
        this.socket.send(JSON.stringify({
          type: "open",
          data:this.$member.token(),
        }))
        this.heart_beat()
      };
      this.socket.onmessage = (event) => {
        let data = JSON.parse(event.data);
        if (data.type==='data'){
          if (data.code===0){
            this.orderLogs.rows.push(data.data);
            setTimeout(function (){
              var top=document.getElementById('detail');
              top.scrollTop=top.scrollHeight;
            },500)
          }
        }else if (data.type === "data_ack") {
          this.orderLogs.rows.forEach((item, index) => {
            if (Object.prototype.hasOwnProperty.call(item,"request_id") && item.request_id === data.request_id) {
              // console.log(item.request_id)
              if (data.code === 0) {
                item.ack = true  //ack==false&&has_error=false发送中,ack==false&&has_error=true 重发
              } else {
                item.has_error = true
              }
              this.orderLogs.rows.splice(index,1,item)
            }
          })
        }
      }
      // 连接关闭后响应
      this.socket.onclose = ()=> {
        console.log("关闭连接");
        this.reconnect();//重连
      }
      this.socket.onerror = (e)=> {
        console.log(e)
        this.reconnect();//重连
      };
    },
    reconnect() {
      if(this.lock.reconnect) return;
      this.lock.reconnect = true;
      //没连接上会一直重连，设置延迟避免请求过多
      setTimeout( () => {
        this.create_ws();
        //console.log("正在重连，当前时间"+new Date())
        this.lock.reconnect = false;
      }, 5000); //这里设置重连间隔(ms)
    },
    heart_beat() {
      if (this.heart_beat_timer) {
        return
      }
      this.heart_beat_timer = setInterval(() => {
        if(this.socket) {
          this.socket.send(JSON.stringify({
            type: 'heart_beat',
            data: "ping"
          }))
        }
      }, 10000)
    },

    addImg(){
      if (this.imgUrl.length<3){
        this.$refs.filElem.dispatchEvent(new MouseEvent('click'))
      }else{
        this.isPrpout=true;
      }
    },
    uploadImg(e){
      this.imgVlaue=[]
      this.isUpload=true;
      let file=e.target.files[0];
      let url='';
      var reader = new FileReader();
      reader.readAsDataURL(file);
      let that=this;
      reader.onload = function (e) {
        url=this.result.substring(this.result.indexOf(',')+1);
        that.imgUrl.push({
          id:that.imgUrl.length+1,
          url:'data:image/png;base64,'+url
        })
        that.imgVlaue.push(
            file
        )

          let formData = new FormData();
          formData.append("group", 'appeals');
          that.imgVlaue.forEach((item)=>{
            formData.append('files', item);
          })
          that.$request.request().addHeader(  'Content-Type','multipart/form-data').post('upload/images',formData).then((response) => {
            var url=null;
            var thumbs=null;
            var request_id=this.guid();
            if (response.data.status===0){
              url=response.data.recordset.urls;
              thumbs=response.data.recordset.thumbs;
            }
            that.socket.send(JSON.stringify({type:'data',request_id:request_id,data:JSON.stringify({content:that.text,pictures:url,thumbs:thumbs,id:that.$route.query.id})}));
            that.orderLogs.rows.push({
              content:"",
              thumbs:thumbs,
              created_at:Math.ceil((new Date()).getTime()/1000),
              request_id:request_id,
              ack:false,
            })
            setTimeout(function (){
              var top=document.getElementById('detail');
              top.scrollTop = top.scrollHeight;
            },500)
          });
      }
    },

    formatDate(time) {
      let date = new Date(time * 1000);
      return date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate() + ' ' + date.getHours() + ':' + date.getMinutes() + ':' + date.getSeconds()
    },
    // 点击确定隐藏
    onConfirm () {
      this.isPrpout = false;
      this.isFalse=false;
      this.isSuccess=false;
      this.isNum=false;
    },
    LoadOrderlogs(page, cb) {
      page = page === undefined ? 1 : page;
      let rows = 10;
      var order_id=this.$route.query.id;
      this.$request.request(this.$member.token()).get('appeal/comments',{id:order_id,page: page,
        row: rows}).then((response) => {
        this.order_total = response.data.recordset.total;
        if (response.data.recordset.page > this.orderLogs.page){
          response.data.recordset.rows.forEach((item) => {
            this.orderLogs.rows.push(item)
          })
          this.orderLogs.total = response.data.recordset.total;
          this.orderLogs.page = response.data.recordset.page;
          this.finished = Math.ceil(this.orderLogs.total / rows) <= page;
          if (cb !== undefined)
            cb();
        }
      });
    },
    onLoad() {
      if (!this.loading) {
        this.loading = !this.loading;
        this.LoadOrderlogs(++this.page, () => {
          setTimeout(() => {
            this.loading = false;
          }, 1000)
        })
      }
    },
    submit(){
        let formData = new FormData();
        formData.append("group", 'appeals');
        this.imgVlaue.forEach((item)=>{
          formData.append('files', item);
        })
            var url=null;
            var thumbs=null;
            var request_id=this.guid();
            this.socket.send(JSON.stringify({type:'data',request_id:request_id,data:JSON.stringify({content:this.text,pictures:url,thumbs:thumbs,id:this.$route.query.id})}));
            this.orderLogs.rows.push({
              content:this.text,
              thumbs:thumbs,
              created_at:Math.ceil((new Date()).getTime()/1000),
              request_id:request_id,
              ack:false,
            })
            this.text='';
            setTimeout(function (){
              var top=document.getElementById('detail');
              top.scrollTop = top.scrollHeight;
            },500)
            this.color='background:#8c8c8c;pointer-events: none';
    },

    write_num(){
      if (this.text.length>0){
        this.color='background: linear-gradient(310deg, #03F8B0 0%, #03A9B2 100%);pointer-events: auto'
      }else{
        this.color='background:#8c8c8c;pointer-events: none'
      }
    }

  }

}
</script>

<style scoped>

</style>