export default function Native() {
    let platform =
        navigator.userAgent.indexOf("Android") > -1
            ? "android"
            : navigator.userAgent.indexOf("iPhone") > -1
            ? "ios"
            : navigator.userAgent.indexOf("Electron") > -1
                ? "windows"
                : false;

    this.do = (method, argv) => {
        switch (platform) {
            case "android":
                argv === undefined || argv === null
                    ? window["android"][method]()
                    : window["android"][method](JSON.stringify(argv));
                break;
            default:
                argv === undefined || argv === null
                    ? window["webkit"]["messageHandlers"][method].postMessage(null)
                    : window["webkit"]["messageHandlers"][method].postMessage(argv);
                break;
        }
    };

    this.switch = (android, other) => {
        platform === "android" ? android() : other();
    };
}