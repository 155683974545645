export default {
    index: {
        meta: {
            title: "我的工单"
        },

    },
    order: {
        meta: {
            title: "提交工单"
        },

    },
    details: {
        meta: {
            title: "工单详情"
        },

    },
    alert: {
        meta: {
            title: "弹出层"
        },

    },
}