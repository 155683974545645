<template>
  <div id="nav">
    <div class="header">
      <div>
        <div class="back" v-on:click="back()"></div>
      </div>
      <div class="title" id="main-title"></div>
<!--      <div class="rule" id="activity-rule" @click="toggleShowRules">{{ $t("home.header.activity_rule") }}</div>-->
    </div>
  </div>
  <transition name="slide-right">
    <div class="container">
      <router-view/>
    </div>
  </transition>

  <div class="mask" v-show="showRules">
    <div id="rules-dialog">
      <div class="rule-content">
        <div class="content">
          <h3>活动规则</h3>
          <p class="rule-title">一、合作共赢·分享推广</p>
          <p>1、分享海报或链接给您的好友 《邀请页》；</p>
          <p>2、好友购买引力会员后，即可获得现金奖励；</p>
          <p>3、好友再次邀请其他人购买，您也可获得返利</p>
          <p class="rule-title">二、现金返利政策</p>
          <p>1、提现发起结算申请时间为：发起申请后3-5个工作日，或在引力APP内联系客服及时提现；</p>
          <p>2、申请提现最低金额为：100元</p>
          <p>3、好友购买后，佣金需等待一定的时间才会返到您的账户，根据好友支付平台的不同返到账户的时间也会不同，一般情况下好友通过支付宝或微信购买的分佣会很快到达您的账户，苹果内购的会稍微久一点。</p>
          <p>本提现规则撰写、生效日：2021年12月01日</p>
        </div>
        <button class="close" @click="toggleShowRules"></button>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
* {
  margin: 0;
  padding: 0;
  //color: #FFFFFF;
  font-family: PingFang SC;
}

body {
  background-color: #021B3A;
}

.mask {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 10000;
}

ul {
  list-style: none;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  //text-align: center;
}

#nav {
  padding: 0.5rem;
  background-color: #021B3A;
  height: 2.08rem;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }

  .header {
    div {
      display: block;
      float: left;
      width: 33.3333%;
      height: 2.08rem;

      .back {
        background: url("/images/back.png") no-repeat left;
        background-size: 50%;
        height: 2.08rem;
      }
    }

    .title {
      font-size: 1.1rem;
      line-height: 2.08rem;
      text-align: center;
      opacity: 1;
      color: #FFFFFF;
    }

    .rule {
      font-size: 14px;
      font-weight: 500;
      line-height: 2.08rem;
      color: #FFFFFF;
      opacity: 1;
      text-align: right;
    }
  }
}

.container {
  width: 94%;
  padding-right: 3%;
  padding-left: 3%;
  background-color: #021B3A;
  display: flex;
  display: -webkit-flex;
  margin: 3.08rem auto 0;
}

a {
  text-decoration: none;
}

a:link {
  text-decoration: none;
}

a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

a:active {
  text-decoration: none;
}

a:focus {
  text-decoration: none;
}

</style>

<script>
import "@/styles/main.scss"
import Native from "@/util/navite";

export default {
  name: 'Main',
  created() {
    // if (this.$router.currentRoute.value.path==='/list'){
    //   document.querySelector('body').setAttribute('style', ' background:#03031C');
    //   document.querySelector('#nav').setAttribute('style', ' background:#03031C');
    //   document.querySelector('.container').setAttribute('style', 'background:#03031C');
    // }
  },
  data() {
    return {
      showRules: false
    }
  },
  methods: {
    toggleShowRules() {
      if (this.$router.currentRoute.value.path==='/'){
        this.showRules = !this.showRules;
      }
    },
    back() {
      let platform = navigator.userAgent.indexOf('Android') > -1 ? 'android' : (navigator.userAgent.indexOf('iPhone') > -1 ? 'ios' : false);
      switch (this.$route.fullPath) {
        case '/'+'?token='+this.$member.token():
          switch (platform) {
            case 'android':
              new Native().do('close');
              break
            default:
              new Native().do('close');
              break
          }
          break
        case '/grade_center?mobile'+'?token='+this.$member.token():
          switch (platform) {
            case 'android':
              new Native().do('close');
              break
            default:
              new Native().do('close');
              break
          }
          break
        default:
          this.$router.back();
          break
      }
    }
  }

}
</script>