import {createApp} from 'vue'
import App from './App.vue'
import {createI18n} from 'vue-i18n'
import Vant from 'vant';
import 'vant/lib/index.css';
import router from './router'
import languages from './languages/languages'
import request from "./request/request";
import Member from "./member/member";

//request.setAPI('http://192.168.50.93:8090/api/');
request.setAPI('https://api.initap.com/api/');

//localStorage.setItem('user-token','eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJJRCI6Ijg3MDQzNSIsIkRhdGEiOiJ7XCJkZXZpY2VfaWRcIjpcImU2NGQxMzJiLTI2ZmQtNGI3ZC04ZGM1LTE4YzI5ZDA2MmIyZFwiLFwicGxhdGZvcm1cIjpcInBjXCIsXCJsYXN0X2xvZ2luX2F0XCI6MTY0ODYzODAwMixcInJlcXVlc3RfaWRcIjpcIjZkZWI0NWUyLTYzOTUtNTJjZi1lZTg0LTY0OTgzYTc2OTVjYVwifSIsImV4cCI6MTY2NDE5MDAwMn0.izkasw6HuesS40m8eXMjY4X2B1duockGQkc-sX5EGn0')


const i18n = createI18n(languages);
i18n.global.locale = navigator.language;

const app = createApp(App).use(i18n).use(Vant);

app.config.globalProperties.$member = new Member();
app.config.globalProperties.$request = request;


router.beforeEach((to, from, next) => {
    if (Object.prototype.hasOwnProperty.call(to.query, 'token')) {
        app.config.globalProperties.$member.setToken(to.query.token);
    }
    app.config.globalProperties.$member.load();
    window.document.title = to.meta.title === undefined ? '默认标题' : i18n.global.t(to.meta.title);
    document.getElementById('main-title').innerText = to.meta['title'] === undefined ? '默认标题' : i18n.global.t(to.meta.title);

    next();
});
app.use(router).mount('#app');