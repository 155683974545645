<template>
  <div id="home">
      <div class="home-top">
        <span v-bind:class="{active: type === 'all'}" v-on:click="changeType('all')">全部</span>
        <span v-bind:class="{active: type === 'processing'}" v-on:click="changeType('processing')">处理中({{processing}})</span>
        <span v-bind:class="{active: type === 'reply'}" v-on:click="changeType('reply')">待我回复({{reply}})</span>
      </div>
    <van-list loading-text="加载中..." v-model="loading" offset="700" :finished="finished" finished-text="没有更多了"
              @load="onLoad">
    <div class="home-middle">
        <router-link :to="{path:'details',query:{id:item.id,status:item.status}}" class="home-middle-content" v-for="(item) in orderLogs.rows" v-bind:key="item.id">
            <div class="left">
              <p>工单号:<span>{{item.id}}</span></p>
              <p>{{item.name}}</p>
              <p><img src="/images/time.png" alt="">{{formatDate(item.created_at)}}</p>
            </div>
            <div class="right">
              <div v-if="item.status===4" class="completed"><span>已完成</span></div>
              <div v-else-if="item.status===2" class="processing"><span>处理中</span></div>
              <div v-else-if="item.status===3" class="reply"><span>待回复</span></div>
              <div v-else-if="item.status===1" class="processed"><span>待处理</span></div>
            </div>
        </router-link>
    </div>
    </van-list>
    <router-link to="/order" class="home-bottom">
        <p><img src="/images/write.png" alt="">提交工单</p>
    </router-link>
  </div>
</template>

<script>
import "@/styles/home.scss";

export default {
  name: "Home",
  created() {

      this.$request.request(this.$member.token()).get('appeal/problems').then((response) => {
        var problem_arr=JSON.stringify(response.data.recordset)
        var first_problem=JSON.stringify(response.data.recordset[0].position)
        localStorage.setItem('problem',problem_arr);
        localStorage.setItem('first_problem',first_problem);
      });

    this.$request.request(this.$member.token()).get('appeal/count').then((response) => {
        if (response.data.recordset){
          var count_arr=response.data.recordset;
          count_arr.forEach((item)=>{
            if (item.status===2){
              this.processing=item.count
            }else{
              this.reply=item.count
            }
          })
        }
    });

    // // 打开websocket
    // this.socket = new WebSocket("ws://" + "192.168.50.93:8090" + "/api/appeal/chat");
    // this.socket.onopen = () => {
    //   this.socket.send(JSON.stringify({
    //     token:this.$member.token() ,
    //   }))
    // };
    // this.socket.onmessage = (event) => {
    //   let data = JSON.parse(event.data);
    //   this.comments.rows.push(data);
    // }

  },
  data() {
    return {
      type: this.$router.currentRoute.value.query.type || 'all',
      orderLogs: {rows: [], page: 0},
      loading: false,
      finished: false,
      page: 0,//页数
      order_total: '',
      processing:0,
      reply:0
    }
  },
  methods: {
    changeType(type){
      this.page = 0;
      this.type = type;
      if (this.type === 'all') {
        this.status = 0;
      } else if (this.type === 'processing') {
        this.status = '2';
      }  else if (this.type==='reply'){
        this.status = "3";
      }
      this.orderLogs.page = 0;
      this.orderLogs.rows = [];
      this.loading= false;
      this.onLoad()
    },
    LoadOrderlogs(page, cb) {
      page = page === undefined ? 1 : page;
      let rows = 10;
      this.$request.request(this.$member.token()).get('appeals',{status:this.status ,page: page,
        row: rows}).then((response) => {
        this.order_total = response.data.recordset.total;
        if (response.data.recordset.page > this.orderLogs.page) {
          var map={};
          var arr=JSON.parse(localStorage.getItem('problem'))
          arr.forEach(function(index){
            map[index.id]=index;
          })
          response.data.recordset.rows.forEach((item) => {
            item.name=map[item.problem_id].name
            this.orderLogs.rows.push(item);
          })
          this.orderLogs.total = response.data.recordset.total;
          this.orderLogs.page = response.data.recordset.page;
          this.finished = Math.ceil(this.orderLogs.total / rows) <= page;
          if (cb !== undefined)
            cb();
        }
      });
    },
    onLoad() {
      if (!this.loading) {
        this.loading = !this.loading;
        switch (this.type) {
          case 'all':
            this.LoadOrderlogs(++this.page, () => {
               setTimeout(() => {
                this.loading = false;
               }, 1000)
            })
            break;
          case 'processing':
            this.LoadOrderlogs(++this.page, () => {
              setTimeout(() => {
                this.loading = false;
              }, 1000)
            })
            break;
          case 'reply':
            this.LoadOrderlogs(this.page += 1, () => {
              setTimeout(() => {
                this.loading = false;
              }, 1000)
            })
            break;
        }
      }

    },
    formatDate(time) {
      let date = new Date(time * 1000);
      return date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate() + ' ' + date.getHours() + ':' + date.getMinutes() + ':' + date.getSeconds()
    },
  }
}
</script>

<style scoped>

</style>