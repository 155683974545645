<template>
  <div id="order">
    <p class="p-type">问题类型</p>
    <van-dropdown-menu>
      <van-dropdown-item  v-model="value1"  :options="option1" />
    </van-dropdown-menu>
    <div class="order-describe">
      <p><span>{{write_num}}</span>/120</p>
      <div class="content">
        <textarea v-model="text" v-on:input="input" maxlength="120"  cols="30" rows="6" placeholder="详细的问题描述往往是非常有必要的哦~"></textarea>
      </div>
    </div>
    <div class="order-pic">
      <div class="order-pic-top">
        <p>添加图片(选填）</p>
<!--        <p><span>{{pic_num}}</span>/3</p>-->
      </div>
      <div class="order-pic-bottom">
        <div v-on:click="add_img('first')">
          <img class="pic1" v-show="pic1" src="/images/pic.png" alt="">
          <img class="pic2" v-show="pic2" :src="(first_src)" alt="">
          <img class="pic3" v-show="pic3" v-on:click.stop="close_pic('first')"  src="/images/close_pic.png" alt="">
        </div>
        <div v-on:click="add_img('second')">
          <img class="pic1" v-show="pic4" src="/images/pic.png" alt="">
          <img class="pic2" v-show="pic5" :src="(second_src)" alt="">
          <img class="pic3" v-show="pic6" v-on:click.stop="close_pic('second')"  src="/images/close_pic.png" alt="">
        </div>
        <div v-on:click="add_img('third')">
          <img class="pic1" v-show="pic7" src="/images/pic.png" alt="">
          <img class="pic2" v-show="pic8" :src="(third_src)" alt="">
          <img class="pic3" v-show="pic9" v-on:click.stop="close_pic('third')"  src="/images/close_pic.png" alt="">
        </div>
      </div>
    </div>
    <div class="order-submit">
      <span v-on:click="submit()">提交</span>
    </div>
    <input type="file" ref="filElem"  @change="uploadImg($event)" class="input_file">
    <Popout v-show="isPrpout" v-on:confirm="onConfirm" content="描述不能为空!!"/>
    <Popout v-show="isSuccess" v-on:confirm="onConfirm" content="提交成功!"/>
    <Popout v-show="isFalse" v-on:confirm="onConfirm" :content="(falseContent)"/>
    <van-loading type="spinner" color="#00BB8C" v-show="loading"/>
    <div v-show="showMask" class="mask" v-on:click="mask_close()">

    </div>
  </div>




</template>

<script>
import "@/styles/order.scss";
import Popout from './Alert.vue'
 import { ref } from 'vue';
export default {
  name: "Order",
  created() {
    // this.loading=true;
    // this.showMask=true;
  },
  setup() {
    const value1 = ref(Number(localStorage.getItem('first_problem')));
    const arr=JSON.parse(localStorage.getItem('problem'));
  var option1=[];
    arr.forEach((item,key)=>{
      var newarr=new Object();
        newarr.text=item.name;
        newarr.value=key;
        newarr.id=item.id;
      option1.push(newarr)
    })
    return {
      value1,
      option1,
    };
  },
  components:{
    Popout
  },
  data(){
    return {
      type: this.$router.currentRoute.value.query.type,
      write_num:'0',
      text:'',
      pic_num:0,
      pic1:true,
      pic2:false,
      pic3:false,
      pic4:true,
      pic5:false,
      pic6:false,
      pic7:true,
      pic8:false,
      pic9:false,
      first_src:'/images/pic.png',
      second_src:'/images/pic.png',
      third_src:'/images/pic.png',
      imgUrl:[],
      isPrpout:false,
      isSuccess:false,
      isUpload:false,
      isFalse:false,
      falseContent:'',
      loading:false,
      showMask:false
    }
  },
  methods:{
    input(){
      this.write_num=this.text.length;
    },
    add_img(type){
      this.$refs.filElem.dispatchEvent(new MouseEvent('click'))
      this.type=type;
      if (type==='first'){
        this.pic1=false;
        this.pic2=true;
        this.pic3=true;
      } else if (type==='second'){
        this.pic4=false;
        this.pic5=true;
        this.pic6=true;
      }else{
        this.pic7=false;
        this.pic8=true;
        this.pic9=true;
      }
    },
    close_pic(type){
      this.type=type;
      if (type==='first'){
        this.pic2=false;
        this.pic3=false;
        this.pic1=true;
      }else if (type==='second'){
        this.pic5=false;
        this.pic6=false;
        this.pic4=true;
      }else{
        this.pic8=false;
        this.pic9=false;
        this.pic7=true;
      }
    },
    uploadImg(e){
      let file=e.target.files[0];
      let url='';
      var reader = new FileReader();
      reader.readAsDataURL(file);
      let that=this;
      reader.onload = function (e) {
        url=this.result.substring(this.result.indexOf(',')+1);
        if (that.type==='first'){
          that.first_src='data:image/png;base64,'+url;
        }else if(that.type==='second'){
          that.second_src='data:image/png;base64,'+url;
        }else{
          that.third_src='data:image/png;base64,'+url;
        }

        that.imgUrl.push(
          file
        )
      }
    },

    submit(){
      let formData = new FormData();
      formData.append("group", 'appeals');
      this.imgUrl.forEach((item)=>{
        formData.append('files', item);
      })
      if (this.text.length===0){
        this.isPrpout=true;
      }else{
        this.loading=true;
        this.showMask=true;
        this.$request.request().addHeader(  'Content-Type','multipart/form-data').post('upload/images',formData).then((response) => {
          var url=null;
          var thumbs=null;
          if (response.data.status===0){
            url=response.data.recordset.urls;
            thumbs=response.data.recordset.thumbs;
          }
          this.$request.request(this.$member.token()).post('appeal',{content:this.text,pictures:url,thumbs:thumbs,question_id:this.option1[this.value1].id}).then((response) => {
            if (response.data.status===0){
              this.loading=false;
              this.showMask=false;
              //this.isSuccess=true;
              setTimeout(function (){
                location.href='/'
              },1000)
            }else{
              this.loading=false;
              this.showMask=false;
              this.isFalse=true;
              this.falseContent=response.data.msg;
            }
          });
        });
      }
    },
    onConfirm (val) {
      this.isPrpout = false;
      this.isSuccess= false;
      this.isFalse=false;
    },
  },




}
</script>

<style scoped>

</style>