import md5 from 'md5';
import {v4 as uuid} from 'uuid';
import axios from "axios";

let api = '';

export default new function () {

    this.setAPI = function (uri) {
        api = uri;
    }

    this.request = function (token) {
        return new Request(api, token);
    }

}

/**
 *
 * @param {string} api
 * @param {string|object} token
 */
function Request(api, token) {

    this.api = api;
    this.token = token === undefined || token === null ? '' : token;
    this.uuid = function () {
        let id = localStorage.getItem('device-uuid');
        if (id === null) {
            id = uuid();
            localStorage.setItem('device-uuid', id);
        }

        return id;
    }

    this.sign = function (headers, parameters) {
        let keys = [];
        if (parameters !== undefined) {
            keys = Object.keys(parameters);
        }

        keys.push('x-platform', 'x-device-id', 'x-timestamp', 'x-device-model')
        keys.sort();
        let keyValues = [];
        keys.forEach((key) => {
            if (Object.prototype.hasOwnProperty.call(headers, key)) {
                keyValues.push(key + '=' + headers[key])
            } else {
                keyValues.push(key + '=' + parameters[key])
            }
        })

        let signStr = keyValues.join('&');
         // console.log(signStr);
        return md5(signStr + '&sign=Xm5XhnH9oX8ebl7k').toUpperCase();
    }

    this.buildHeaders = function (parameters) {
        let headers = {
            'x-platform': 'chrome',
            'x-device-id': this.uuid(),
            'x-timestamp': Date.parse(new Date().toString()) / 1000,
            'x-device-model': 'pc',
        };

        let token = this.token;
        if (token !== '') {
            headers['Authorization'] = token;
            let device = JSON.parse(JSON.parse(atob(token.split('.')[1]))['Data']);
            headers['x-platform'] = 'pc';
            headers['x-device-id'] = device['device_id'];
        }

        headers['sign'] = this.sign(headers, parameters);
        headers['x-want-sign'] = 'webview';

        headers = Object.assign({}, this.headers, headers)

        // console.log(headers);
        return headers
    }

    this.headers = {};

    this.addHeader = function (key, value) {
        this.headers[key] = value;
        return this;
    }

    this.get = function (url, parameters) {
        return axios.get(this.api + url, {
            params: parameters, headers: this.buildHeaders({})
        })
    }

    this.post = function (url, parameters) {
        return axios.post(this.api + url, parameters, {
            headers: this.buildHeaders(parameters)
        })
    }
}