import en from "./en"
import zh from "./zh"

export default {
    locale: 'zh-CN',
    messages: {
        'zh-CN': zh,
        'en-US': en
    }
}

